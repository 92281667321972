export const translations: any = {
"error_happened": "Une erreur s'est produite",
"yup_string_max": "Le champ ne doit pas excéder 255 caractères.",
"yup_description_max": "La champ ne doit pas excéder 500 caractères.",
"yup_password_min": "Le champ doit contenir au moins 4 caractères.",
"yup_phone_format": "Le format du numéro doit être respecté 06XXXXXXXX",
"yup_max_default": "Ce champ ne doit pas excéder 1000 caractères",
"yup_field_required": "Ce champ est obligatoire.",
"yup_min_two": "2 caractères minimum",
"modal_confirmation_title": "Attention !",
"modal_confirmation_yes": "Oui",
"modal_confirmation_no": "Non",
"modal_cancel": "Annuler",
"modal_done": "Terminer",
"modal_passphrase_action_required": "Action requise",
"base_path_1": "Coffres",
"files_dropzone_title": "Sélection de fichiers",
"files_dropzone_description": "Glisser vos fichiers ici ou",
"files_dropzone_browse": "parcourir",
"files_dropzone_side_sentence": "vos fichiers",
"files_dropzone_delete_all": "Supprimer tout",
"files_dropzone_upload": "Télécharger tout",
"navbar_space_tooltip": "Cliquez ici pour changer d'espace Wishbook",
"navbar_status_guardian_format": "vous a nommé",
"navbar_status_wishbooker": "Wishbooker",
"navbar_status_guardian": "Gardien",
"navbar_quit_space": "Quitter l'espace",
"navbar_switch_space": "Changer d'espace",
"navbar_question": "Une question ?",
"navbar_contact_us": "Contactez-nous",
"footer_follow_us": "Suivez-nous",
"navbar_section_0": "WISHBOOKER",
"navbar_section_1": "MES COFFRES",
"navbar_connected_as": "Connecté en tant que",
"navbar_current_plan": "Plan actuel",
"navbar_access_to_safe": "Accès au coffre de",
"navbar_dashboard": "Tableau de bord",
"navbar_subscriptions": "Souscriptions",
"topbar_account_menu_account": "Compte",
"topbar_account_menu_logout": "Déconnexion",
"navbar_documents": "Documents",
"navbar_memories": "Souvenirs",
"navbar_funerals": "Volontés funéraires",
"navbar_credentials": "Patrimoine numérique",
"navbar_directives": "Directives anticipées",
"navbar_testament": "Testament",
"navbar_guardians": "Gardiens",
"navbar_albums": "Albums",
"navbar_ff": "Amis & Familles",
"navbar_timelapse": "Vidéo timelapse",
"navbar_savings": "Épargne",
"navbar_pot": "Cagnotte",
"navbar_health": "Carnet de santé",
"documents_path_2": "Documents",
"documents_path_3": "Fichiers",
"kids_documents_title": "L'essentiel de ses documents",
"kids_documents_description": "Réunissez les documents importants : diplômes, attestations, livret de famille, pièce d'identité, dossiers d'écoles… Tout ce qui pourra lui être utile même adulte",
"documents_title": "Documents",
"documents_folder_title": "Vos documents",
"documents_title_description": "Les documents du quotidien peuvent paraître anodins mais si vous aviez ceux de vos ancêtres, imaginez tout ce que vous sauriez sur eux. Une pièce d’identité, un acte de mariage, des diplômes, une parution sur vos réalisations, un acte de propriété, un brevet industriel… Ces documents sont des témoignages de vie.",
"documents_modal_button_title": "Nouveau dossier",
"documents_modal_title": "Créer un dossier",
"documents_modal_description": "Indiquez un nom et une petite description donnant du contexte à votre nouveau dossier.",
"documents_modal_form_title": "Nom du dossier",
"documents_modal_form_description": "Description",
"documents_modal_form_description_placeholder": "Vous pouvez décrire votre nouveau dossier ici...",
"documents_modal_add_form_success": "Dossier créé avec succès !",
"documents_modal_add_form_failure": "Une erreur s'est produite lors de le la création.",
"documents_modal_form_tags": "Tags",
"documents_modal_form_submit": "Créer",
"documents_folder_delete_modal_confirm": "Etes-vous sûr de vouloir supprimer ce dossier ?",
"documents_folder_delete_modal_success": "Dossier supprimé avec succès !",
"documents_folder_delete_modal_failure": "Une erreur s'est produite lors de la suppression.",
"documents_folder_add_files": "Ajouter fichiers",
"documents_folder_delete": "Supprimer dossier",
"documents_file_upload_modal_title": "Ajouter des fichiers",
"documents_file_upload_modal_description": "Vous pouvez déposer ou ajouter une photo dans la zone ci-dessous.",
"documents_file_delete_modal_success": "Fichier supprimé avec succès !",
"documents_file_delete_modal_failure": "Une erreur s'est produite lors de la suppression.",
"documents_file_delete_modal_confirm_one_file": "Etes-vous sûr de vouloir supprimer ce fichier ?",
"documents_file_delete_modal_confirm_multiple_files": "Etes-vous sûr de vouloir supprimer ces fichiers ?",
"documents_file_form_edit_label": "Editer",
"documents_file_form_delete_label": "Supprimer",
"documents_file_list_type": "Type",
"documents_file_list_name": "Nom",
"documents_file_list_description": "Description",
"documents_file_list_date": "Date",
"documents_file_list_actions": "Actions",
"kids_memories_title": "Ses souvenirs",
"kids_memories_description": "Photos, vidéos, petits mots, dessins, premiers grands événements... C'est un condensé d'amour qui suivra votre enfant toute sa vie.",
"memories_title": "Souvenirs d’une vie",
"memories_title_description": "Ce coffre n’est pas le plus simple à remplir. Ici, parlez de ",
"memories_title_description_in_between": "VOUS",
"memories_title_description2": " et des grandes étapes de votre vie. C’est ici que vous racontez votre histoire, que vous donnez vos conseils de vie ainsi que des messages pour vos proches et les générations futures.",
"memories_title_guardian_description": "Ce coffre contient ses souvenirs les plus précieux, les grandes étapes de sa vie, son histoire, ses conseils de vie ainsi que des messages pour ses proches et les générations futures.",
"memories_tab_1": "Ma vidéo",
"memories_tab_2": "Mes albums",
"memories_tab_3": "Moi",
"memories_video_call_to_action_description": "Avec l’ensemble de vos photos favorites, et de vos albums préférés, Wishbook génère automatiquement une vidéo “souvenirs“.\nCliquez sur l'étoile de chaque photo pour qu’elle apparaisse dans cette vidéo. Cette fonctionalité sera disponible bientôt.",
"memories_video_call_to_action_checkbox": "Me signaler lorsque ma vidéo sera disponible.",
"memories_add_collection_modal": "Nouvel album",
"member_create_specific_album": "Créer un album dédié",
"memories_add_collection_form_header_title": "Créer un album",
"memories_add_collection_form_header_description": "Indiquez un nom et une petite description donnant du contexte à votre nouvel album",
"memories_add_collection_form_title_label": "Nom",
"memories_add_collection_form_description_label": "Description",
"memories_add_collection_form_description_placeholder": "Quel message souhaitez-vous transmettre grâce à cet album ?",
"memories_add_collection_form_question_mark": "Les indications que vous laissez sur votre album permettront à vos proches d'avoir plus d'informations sur qui vous êtes.",
"memories_add_collection_form_submit": "Créer album",
"memories_add_form_success": "Album créé avec succès !",
"memories_add_form_failure": "Une erreur s'est produite lors de le la création.",
"memories_in_collection_change_cover": "Changer la couverture",
"memories_in_collection_delete": "Supprimer",
"memories_in_collection_edit": "Editer",
"memories_in_collection_update": "Sauvegarder",
"memories_in_collection_add": "Ajouter photo",
"memories_in_collection_start_date": "Début",
"memories_in_collection_end_date": "Fin",
"memories_in_collection_delete_success": "Album supprimé avec succès !",
"memories_in_collection_delete_failure": "Une erreur s'est produite lors de la suppression.",
"memories_in_collection_update_success": "Album modifié avec succès !",
"memories_in_collection_update_failure": "Une erreur s'est produite lors de la modification.",
"memories_in_collection_add_success": "Album ajouté avec succès !",
"memories_in_collection_add_failure": "Une erreur s'est produite lors de l'ajout.",
"memories_in_collection_starred": "Photo ajoutée aux favoris !",
"memories_in_collection_unstarred": "Photo retirée des favoris !",
"memories_in_collection_starred_failure": "Une erreur s'est produite sur les favoris.",
"memories_media_date": "Date de l'évènement",
"memories_media_filename": "Nom du fichier",
"memories_media_description": "Description",
"memories_media_modal_title": "Ajouter une photo",
"memories_media_modal_description": "Vous pouvez déposer ou ajouter une photo dans la zone ci-dessous.",
"memories_in_media_submit": "Enregistrer",
"memories_in_media_submit_success": "Les informations ont bien été modifiées.",
"memories_in_media_submit_failure": "Une erreur s'est produite lors des modifications.",
"memories_collection_title": "Album",
"memories_media_title": "Media",
"memories_album_delete_modal_message": "Etes-vous sûr de vouloir supprimer cet album ?",
"memories_media_delete_action": "Supprimer",
"memories_starred_type": "Type",
"memories_starred_name": "Nom",
"memories_starred_description": "Description",
"memories_other_variant_success": "Enregistré !",
"memories_other_variant_failure": "Une erreur s'est produite lors de l'envoi du formulaire.",
"memories_other_page_title": "Démarrer mon Wishbook",
"memories_other_header_title": "Je démarre mon Wishbook",
"memories_other_header_description": "Ici, vous remplissez la base de votre Wishbook. Ce sera le point de départ de la vidéo de votre vie, générée automatiquement. Le tout sera récupéré par vos proches puis les générations futures qui pourront mieux comprendre qui vous êtes et d’où elles viennent. Pour faire simple, imaginez si vous-même aviez recueilli toutes ces informations renseignées par les générations précédentes de votre famille.",
"memories_other_form_root_title": "Qui suis-je ?",
"memories_other_form_root_origin_label": "Origines de mon nom de famille",
"memories_other_form_root_religion_label": "Mes religions et croyances",
"memories_other_parents_checkbox_label": "Je souhaite parler de mes parents",
"memories_other_parents_title": "Mes parents",
"memories_other_parents_one": "Parent 1",
"memories_other_parents_two": "Parent 2",
"memories_other_family_lastname_label": "Nom",
"memories_other_family_firstname_label": "Prénom",
"memories_other_family_gender_label": "Genre",
"memories_other_family_gender_option_default": "Sélectionnez",
"memories_other_family_gender_option_male": "Frère",
"memories_other_family_gender_option_female": "Soeur",
"memories_other_family_gender_option_father": "Père",
"memories_other_family_gender_option_mother": "Mère",
"memories_other_family_dob_label": "Date de naissance",
"memories_other_family_birth_location_label": "Commune",
"memories_other_family_origin_label": "Origines",
"memories_other_family_beliefs_label": "Religions et croyances",
"memories_other_family_occupation_label": "Métiers",
"memories_other_family_description_label": "Quelques mots",
"memories_other_siblings_title": "Mes frères et soeurs",
"memories_other_siblings_label": "Frères/Soeur",
"memories_other_souvenirs_title": "Mes souvenirs",
"memories_other_souvenirs_guidance_label": "Mes conseils de vie pour les générations futures",
"memories_other_souvenirs_experience_label": "Mon expérience la plus enrichissante",
"memories_other_souvenirs_guilty_pleasures_label": "Mes péchés mignons",
"memories_other_passion_card_title": "Mes passions",
"memories_other_passion_label": "Décrivez vos passions ici",
"memories_other_travels_title": "Mes voyages",
"memories_other_travels_label": "Liste des pays visités",
"memories_other_lived_place_card_title": "Là où j'ai vécu",
"memories_other_lived_place_address": "Adresse",
"memories_other_lived_place_description": "Note",
"memories_other_quotes_title": "Mes citations préférées",
"memories_other_quotes_quote": "Citation",
"memories_other_quotes_author": "Auteur",
"memories_other_quotes_note": "Note",
"memories_other_books_card_title": "Mes livres préférés",
"memories_other_books_title": "Titre",
"memories_other_books_author": "Auteur",
"memories_other_books_note": "Note",
"memories_other_movies_card_title": "Mes films préférés",
"memories_other_movies_title": "Titre",
"memories_other_movies_author": "Réalisateur",
"memories_other_movies_description": "Notes",
"memories_other_musics_card_title": "Mes musiques préférées",
"memories_other_musics_title": "Morceau",
"memories_other_musics_author": "Artiste",
"memories_other_musics_album": "Album",
"memories_other_musics_note": "Notes",
"memories_other_recipes_card_title": "Mes recettes préférées",
"memories_other_recipes_title": "Intitulé",
"memories_other_recipes_recipe": "Recette",
"memories_other_recipes_note": "Notes",
"memories_other_text_other_card_title": "Pour aller plus loin",
"memories_other_text_other_description_placeholder": "Si vous avez des éléments à rajouter, vous avez cette zone de texte libre :",
"funerals_title": "Volontés funéraires",
"funerals_title_description": "Même si parler de sa propre mort n’est pas simple dans certaines cultures, c’est ce qui nous rend égaux et humains avant tout. Le sujet n’a donc plus besoin d’être abordé de vive voix avec ceux que vous aimez.\nUne fois écrites, vos volontés possèdent une valeur testamentaire car selon l'article 3 de la loi du 15 novembre 1887 les désirs d'un défunt doivent parfaitement être respectés.",
"funerals_organization_title": "Organisation",
"funerals_organization_explanation_title": "Plus de détails sur vos volontés",
"funerals_organization_explanation_placeholder": "Texte libre",
"funerals_organization_ceremony_title": "Quel type de cérémonie désirez-vous ?",
"funerals_organization_ceremony_label": "Cérémonie",
"funerals_organization_ceremony_type_default": "Sélectionnez",
"funerals_organization_ceremony_type_1": "Religieuse",
"funerals_organization_ceremony_type_2": "Laïque",
"funerals_organization_ceremony_type_3": "Autre",
"funerals_organization_ceremony_type_other_label": "Autre",
"funerals_organization_ceremony_type_other_placeholder": "Veuillez préciser le type de cérémonie.",
"funerals_organization_body_title": "Quels types de funérailles désirez-vous ?",
"funerals_organization_body_label": "Funérailles",
"funerals_organization_body_description": "Incinération/Enterrement/Don du corps à la science",
"funerals_organization_body_option_default": "Sélectionnez",
"funerals_organization_body_option_1": "Crémation",
"funerals_organization_body_option_2": "Enterrement",
"funerals_organization_body_option_3": "Don du corps à la science",
"funerals_organization_body_option_4": "Autre",
"funerals_organization_body_other_placeholder": "Veuillez préciser le type de funérailles.",
"funerals_theme_title": "Thème",
"funerals_artefact_title": "Environnement",
"funerals_organization_flowers_title": "Voulez-vous des fleurs lors de ces funérailles ?",
"funerals_organization_flowers_label": "Fleurs",
"funerals_organization_flowers_option_default": "Sélectionnez",
"funerals_organization_flowers_option_1": "Oui",
"funerals_organization_flowers_option_2": "Non",
"funerals_organization_flowers_yes_placeholder": "Si oui, mes préférés",
"funerals_organization_music_title": "Une musique pour leur dire au revoir",
"funerals_organization_music_label": "Musique",
"funerals_organization_music_type_label": "Type de musique",
"funerals_organization_music_type_placeholder": "Quel est le type de musique",
"funerals_organization_color_title": "La couleur que je préfère (que mes proches pourraient porter ?)",
"funerals_organization_color_label": "Couleur",
"funerals_organization_color_description_label": "Description de la couleur",
"funerals_organization_color_description_placeholder": "Décrivez la couleur",
"funerals_organization_artifact_title": "Un objet ou une photo avec lequel/laquelle je souhaite partir",
"funerals_organization_artifact_label": "Objet/Photo",
"funerals_organization_event_title": "Ce que j'aimerais que vous organisiez ensemble pour honorer ma mémoire (un restaurant en particulier, la maison de famille, une veillée...)",
"funerals_organization_event_label": "Mémoire",
"funerals_organization_message_title": "Un message à lire le jour de la cérémonie :",
"funerals_organization_message_label": "Message",
"funerals_organization_picture_title": "Une photo de moi pour la cérémonie",
"funerals_organization_picture_label": "Photo",
"funerals_organ_donation_title": "Don d'organes",
"funerals_organ_donation_description": "Depuis 2017, toute personne décédée peut être prélevée à moins qu'elle ne se soit inscrite sur le fichier national de refus interdisant cette pratique.",
"funerals_organ_donation_step_1": "Télécharger le formulaire officiel.",
"funerals_organ_donation_step_2": "Imprimez-le.",
"funerals_organ_donation_step_3": "Remplissez, datez et signez le.",
"funerals_organ_donation_step_4": "Scannez-le et déposez-le ci-dessous.",
"funerals_submit": "Enregistrer",
"funerals_variant_success": "Enregistré !",
"funerals_variant_failure": "Une erreur s'est produite lors de l'envoi du formulaire.",
"funerals_partners_title": "Notre partenaire",
"funerals_partners_description_format": "Notre partenaire pour créer un évènement sur-mesure: ",
"funerals_main_partner": "La Fabrique des Adieux",
"credentials_path_1": "Coffre",
"credentials_path_2": "Patrimoine",
"credentials_path_3": "Modifier",
"credentials_title": "Patrimoine Numérique",
"credentials_description": "Confiez la fermeture de vos comptes numériques à vos proches afin que vos comptes soient fermés : impôts, énergie, assurance, véhicules, télécommunications, Facebook, Twitter, Google etc. Les réseaux ne permettent pas toujours la fermeture des comptes même sur présentation d’un acte de décès, la requête est souvent fastidieuse pour les proches.",
"credentials_guardian_description": "Votre proche laisse ses accès numériques afin que ses comptes soient fermés après son grand départ. En effet, parfois, la présentation d’un acte de décès ne suffit pas à faire fermer des comptes en ligne et cela devient pénible pour l’entourage. Ici, le nécessaire est fait.",
"credentials_list_title": "La liste de vos accès aux plateformes et services :",
"credentials_list_empty_description": "Vous trouverez ici la liste des accès numériques que vous renseignez dans votre coffre.",
"credentials_add_form_button": "Ajouter",
"credentials_add_form_title": "Créer un nouveau portail",
"credentials_add_form_title_description": "Veuillez indiquer les informations nécessaires à la création d'un patrimoine.",
"credentials_add_form_name_label": "Nom de l'accès",
"credentials_add_form_description_label": "Description",
"credentials_add_form_description_placeholder": "A quoi donnent accès ces identifiants ?",
"credentials_add_form_type_option_default": "Sélectionnez le type",
"credentials_add_form_type_option_1": "Numéro de téléphone",
"credentials_add_form_type_option_2": "Mail",
"credentials_add_form_type_option_3": "Réseaux Sociaux",
"credentials_add_form_type_option_4": "Compte bancaire",
"credentials_add_form_type_option_5": "Autre",
"credentials_add_form_url_label": "Lien ou lieu",
"credentials_add_form_login_label": "Identifiant",
"credentials_add_form_password_label": "Mot de passe",
"credentials_add_form_question_mark": "Pensez à ajouter des précisions, notamment si vous souhaitez que vos proches ferment votre compte sur ce portail",
"credentials_add_form_submit": "Créer patrimoine",
"credentials_update_form_title": "Modifier un patrimoine",
"credentials_update_form_title_description": "Pensez à bien enregistrer les modifications effectuées.",
"credentials_submit": "Enregistrer",
"credentals_update": "Modifier",
"credentials_delete": "Supprimer",
"credentials_delete_confirm_description": "Etes-vous sûr(e) de vouloir supprimer ce patrimoine ?",
"credentials_add_variant_success": "Patrimoine créé avec succès !",
"credentials_add_variant_failure": "Une erreur s'est produite à la création du formulaire.",
"credentials_update_variant_success": "Patrimoine modifié avec succès !",
"credentials_update_variant_failure": "Une erreur s'est produite lors des modifications.",
"credentials_delete_variant_success": "Patrimoine supprimé avec succès !",
"credentials_delete_variant_failure": "Une erreur s'est produite lors de la suppression.",
"directives_title": "Directives anticipées",
"directives_description": "Toute personne majeure peut rédiger des directives anticipées pour exprimer ses souhaits concernant sa fin de vie. Ce document est le seul qui reste accessible par vos gardiens de votre vivant.",
"directives_guardian_description": "Votre proche exprime ici ses souhaits concernant les soins médicaux. Ce document est le seul auquel vous avez accès du vivant de votre wishbooker.",
"directives_step_1": "ARRÊT DE TRAITEMENT",
"directives_step_2": "RÉANIMATION NÉCESSAIRE",
"directives_step_3": "INTERVENTION CHIRURGICALE",
"directives_step_4": "ARRÊT DES SOUFFRANCES",
"directives_form_label": "Je souhaite rédiger le formulaire.",
"directives_form_step_1": "Télécharger le formulaire officiel.",
"directives_form_step_2": "Imprimez-le.",
"directives_form_step_3": "Remplissez, datez et signez le.",
"directives_form_step_4": "Scannez-le et déposez-le ci-dessous.",
"directives_save_label": "Sauvegarder une copie sur Wishbook",
"directives_location_title": "Où avez-vous rangé l'original ?",
"directives_location_description": "Votre légataire aura besoin aussi de l'original.",
"directives_location_label": "Lieu",
"directives_description_title": "Explication",
"directives_description_text": "Vous pouvez ajouter un texte explicatif supplémentaire ci-dessous.",
"directives_description_placeholder": "Ecrire le texte ici...",
"directives_submit": "Enregistrer",
"directives_variant_success": "Enregistré !",
"directives_variant_failure": "Une erreur s'est produite lors de l'envoi du formulaire.",
"testament_title": "Testament Olographe",
"testament_description": "Il s'agit de la forme de testament la plus facile à réaliser, juridiquement valable à condition de suivre les règles strictes à lire attentivement ci-après.",
"testament_guardian_description": "Si votre proche a rédigé un testament, vous le trouverez ici post-mortem.",
"testament_indication_title": "Indications",
"testament_indication_step_1": "Manuscrit c'est à dire intégralement écrit la main (impossible d'utiliser un ordinateur ou une machine à écrire) à l'aide d'un stylo à encre ou un feutre (pas d'un crayon papier), de préférence sur une feuille blanche.",
"testament_indication_step_2": "Identifiable, ce qui signifie qu'il doit faire mention du lieu de rédaction du testament et décliner l'état civil complet (nom, prénoms, date et lieu de naissance) et l'adresse exacte du testateur et de ses légataires.",
"testament_indication_step_3": "Daté précisément (année exprimée avec quatre chiffres, mois écrit en lettres et jour), une façon de vérifier qu'à cette période, le testateur disposait bien de toutes ses facultés mentales.",
"testament_indication_step_4": "Concis et précis, rédigé plutôt au style direct (\"je lègue tel objet à telle personne\" au lieu de \"je pense léguer tel objet à untel si telle circonstance se produit\") et sans ajouts, termes barrés, ratures...qui risqueraient de troubler la lecture du testament.",
"testament_indication_step_5": "Signé, de préférence à la fin du document plutôt qu'au début, en évitant les initiales ou les seuls prénoms ou surnoms(même s'ils permettent d'identifier le testateur sans ambiguïté).",
"testament_indication_step_6": "Numéroté et, s'il comprend plusieurs feuillets,en précisant dès la première page leur nombre total (par exemple, pour un document de 4 pages 1/4, 2/4, 3/4, 4/4, pour écarter toute contestation).",
"testament_indication_step_7": "Ajoutez sur votre testament que ce présent document est dans votre coffre wishbook ainsi que le lieu physique.",
"testament_guide_title": "Comment rédiger un testament depuis chez vous?",
"testament_guide_description": "Téléchargez votre guide de rédaction, puis stockez votre testament sur Wishbook afin de le transmettre.",
"testament_guide_cta": "Télécharger maintenant",
"testament_location_title": "Où avez-vous rangé l'original ?",
"testament_location_description_text": "Pour que testament olographe possède une réelle valeur juridique, il est essentiel de préciser l’emplacement du document papier.",
"testament_location_label": "Lieu",
"testament_description_title": "Explication",
"testament_description_text": "Vous pouvez ajouter un texte explicatif supplémentaire ci-dessous.",
"testament_description_placeholder": "Ecrire le texte ici...",
"testament_save_label": "Sauvegardez ici une copie de votre testament.",
"testament_submit": "Enregistrer",
"testament_variant_success": "Enregistré !",
"testament_variant_failure": "Une erreur s'est produite lors de l'envoi du formulaire.",
"timelapse_title": "Video timelapse",
"timelapse_description": "Chaque anniversaire vous prenez votre enfant en photo et au bout de 18 ans vous aurez une vidéo timelapse générée automatiquement pour revivre son évolution.",
"timelapse_step_year_birth": "Naissance",
"timelapse_step_year_format": "{0} an",
"timelapse_step_years_format": "{0} ans",
"timelapse_download_action": "Télécharger la vidéo",
"timelapse_coming_soon": "Vous serez averti par email lorsque votre vidéo sera prête. Cette fonctionnalité arrive très bientôt sur Wishbook.",
"health_title": "Carnet de santé",
"health_description": "Créez un suivi avec les vaccins à jour, les prochains rappels, les allergies, les maladies contagieuses, son groupe sanguin, les dossiers médicaux...",
"pot_title": "Cagnotte",
"pot_description": "\"Que désire votre enfant pour son anniversaire ?\". Plutôt qu'un petit cadeau chacun, comblez ses désirs avec un beau cadeau commun !",
"savings_title": "Epargne",
"savings_description": "Ajoutez le RIB de son compte épargne pour financer ses débuts de vie de jeune adulte. Objectifs : permis, voiture, meubler son premier appartement...",
"authcover_title": "Créer votre Wishbook est un acte rempli de sens et d'amour.",
"authcover_arg1": "Centralisez et Sécurisez toute votre succession en un unique endroit",
"authcover_arg2": "Service recommandé par les Conseillers en Gestion de Patrimoine",
"authcover_review_label": "Avis clients",
"authcover_press_title": "PLÉBISCITÉ PAR",
"login_title": "Authentification",
"login_description": "Accédez à votre Wishbook en toute sécurité",
"login_reset_password": "Mot de passe oublié ?",
"login_reset_password_reset_link": "Réinitialiser",
"login_register": "Pas encore inscrit ?",
"login_register_link": "Cliquez ici pour créer votre Wishbook",
"login_register_guardian": "Vous êtes un gardien ?",
"login_login_register_link_as_guardian": "Cliquez ici pour créer votre compte",
"login_login": "Se connecter",
"login_form_login_label": "Adresse email",
"login_form_password_label": "Mot de passe",
"login_form_login_required": "Une adresse email valide est requise",
"login_form_password_required": "Le mot de passe est requis",
"login_form_error_wrong_email_password": "Votre email ou votre mot de passe est erroné",
"reset_password_title": "Mot de passe oublié",
"reset_password_description": "Vous recevrez les instructions par email",
"reset_password_reset": "Réinitialiser",
"reset_password_success": "Un email vous a été envoyé avec les instructions pour réinitialiser votre mot de passe",
"reset_password_failure": "Une erreur est survenue, veuillez contacter le support client.",
"reset_password_login": "C'est fait ?",
"reset_password_login_link": "Cliquez ici pour vous connecter",
"register_title": "Inscription",
"register_as_guardian_title": "Inscription en tant que gardien",
"register_description": "Avant toute chose, veuillez créer vos identifiants qui vous permettront de vous connecter.",
"register_already_login": "Déjà inscrit ?",
"register_login_link": "Cliquez ici pour accéder à votre Wishbook",
"register_register": "S'inscrire",
"register_form_first_name_label": "Prénom",
"register_form_first_name_min": "2 caractères min",
"register_form_first_name_required": "Prénom requis",
"register_form_last_name_label": "Nom",
"register_form_last_name_min": "2 caractères min",
"register_form_last_name_required": "Nom requis",
"register_form_email_label": "Adresse email",
"register_form_password_label": "Mot de passe",
"register_form_password_helper_intro": "Votre mot de passe doit contenir au moins ",
"register_form_password_helper_rule_1": "1 majuscule",
"register_form_password_helper_rule_2": "1 minuscule",
"register_form_password_helper_rule_3": "1 chiffre",
"register_form_password_helper_rule_4": "6 caractères minimum",
"register_form_register_required": "Une adresse email valide est requise",
"register_form_password_required": "Le mot de passe est requis",
"register_form_password_confirmation_required": "La confirmation du mot de passe est requise",
"register_form_dob_required": "La date de naissance est requise",
"register_form_dob_max": "Vous devez avoir au moins 18 ans pour vous inscrire",
"register_form_dob_guardian_max": "Le gardien doit être majeur",
"register_form_marketing_optin": "J'autorise Wishbook à m'envoyer sa newsletter",
"register_form_legals_optin": "J'ai lu les",
"register_form_legals_terms": "Conditions Générales d'Utilisation",
"register_form_legals_optin_2": "et la",
"register_form_legals_privacy_policy": "Politique de Confidentialité",
"register_form_legals_optin_required": "Vous devez confirmer la lecture des documents légaux",
"register_form_error": "Les informations fournies sont erronées",
"register_form_error_conflict": "Cet utilisateur existe déjà, veuillez vous authentifiez pour accéder à votre Wishbook",
"register_form_error_no_wishbookers": "Vous devez être désigné.e par un Wishbooker afin de poursuivre",
"register_email_validation_fallback": "Si le lien a expiré, réessayez en cliquant ici",
"register_email_validation_login": "Poursuivre",
"register_form_email_confirmation_label": "Confirmation d'adresse email",
"regiester_form_email_confirmation_match": "Cette confirmation ne correspond pas à votre adresse",
"register_form_password_confirmation_label": "Confirmation du mot de passe",
"register_form_password_confirmation_match": "Ce champs ne correspond pas à votre mot de passe",
"register_form_phone_label": "Numéro de téléphone",
"register_form_dob_label": "Date de naissance",
"email_validation_title": "Validation de votre email",
"email_validation_description": "Pour plus de sécurité, nous devons nous assurer de la validité de votre adresse",
"email_validation_details": "Vous allez recevoir un email dans quelques instants, veuillez cliquer sur le lien à l'intérieur afin de vérifier votre adresse email avant de poursuivre.",
"email_validation_login": "Se connecter",
"email_validation_logout": "Se déconnecter",
"stepper_label_step": "Etape",
"onboarding_title": "Accompagnement",
"stepper_1_title": "Inscription",
"stepper_1_description": "Sélectionnez un profil ",

// Kids Wishbooker
"kids_stepper_w2_title": "Sélectionnez votre offre",
"kids_stepper_w2_description": "Ces informations sont nécessaires à la validation du paiement",
"kids_stepper_w3_title": "Vous et votre Kid",
"kids_stepper_w3_description": "Qui êtes-vous ?",
"kids_stepper_w4_title": "Configuration",
"kids_stepper_w4_description": "Votre coffre Kids nécessite d'être configuré",
"kids_stepper_w5_title": "Désignez vos proches",
"kids_stepper_w5_description": "Tous ceux qui auront accès à vos coffres, vos amis, votre famille.",

// Kids Member
"kids_stepper_g2_title": "Vos informations",
"kids_stepper_g2_description": "Qui êtes-vous ?",

// Classic Wishbooker
"classic_stepper_w2_title": "Choisissez votre souscription",
"classic_stepper_w2_description": "Laissez vous tenter par votre coffre à vie ou essayez-le gratuitement pendant 1 mois",
"classic_stepper_w3_title": "Dites-nous en plus sur vous",
"classic_stepper_w3_description": "Ces informations seront rattachées à votre coffre",
"classic_stepper_kyc_title": "Document d'identité",
"classic_stepper_kyc_description": "Ces documents certifient votre identité",
"classic_stepper_passphrase_title": "La clé de votre coffre",
"classic_stepper_passphrase_description": "Souvenez-vous en, c'est essentiel !",
"classic_stepper_members_title": "Désignez vos gardiens",
"classic_stepper_members_description": "Ils auront accès à votre coffre le moment venu",
"classic_freemium_w3_title": "Dites-nous en plus sur vous",
"classic_freemium_w3_description": "Ces informations nous permettent configurer votre Wishbook",
"classic_freemium_w4_title": "Dites-nous en plus sur vous",
"classic_freemium_w4_description": "Ces informations nous permettent configurer votre Wishbook",
"classic_freemium_w5_title": "Dites-nous en plus sur vous",
"classic_freemium_w5_description": "Ces informations nous permettent configurer votre Wishbook",
"classic_freemium_w6_title": "Dites-nous en plus sur vous",
"classic_freemium_w6_description": "Ces informations nous permettent configurer votre Wishbook",
"classic_freemium_w7_title": "Désignez vos gardiens",
"classic_freemium_w7_description": "Ils auront accès à votre coffre le moment venu",

// Classic Member
"classic_stepper_g2_title": "Document d'identité",
"classic_stepper_g2_description": "Ces documents certifient votre identité",
"classic_stepper_g3_title": "Une offre unique, à vie",
"classic_stepper_g3_description": "Wishbook c'est la transparence d'une offre unique garantissant la sécurité de votre succession, à vie",
"onboarding_back_action": "Retour",
"onboarding_next_action": "Suivant",
"onboarding_start_action": "Démarrer",
"onboarding_step_usertype_title": "Wishbooker ou Proche ?",
"onboarding_step_usertype_description": "Souhaitez-vous créer votre Wishbook ou accéder à celui d'un proche ?",
"onboarding_step_usertype_wishbooker_title": "Je suis Wishbooker",
"onboarding_step_usertype_wishbooker_subtitle": "Je souhaite créer mon coffre-fort Wishbook",
"onboarding_step_usertype_guardian_title": "Je suis un Proche Désigné",
"onboarding_step_usertype_guardian_subtitle": "Un wishbooker m'a donné accès à son coffre-fort.",
"onboarding_error_no_wishbookers": "Vous devez être désigné.e par un Wishbooker afin de poursuivre",
"step_usertype_wishbookers_title": "Les wishbookers qui vous ont désigné",
"step_usertype_wishbookers_description": "Vous avez été désigné.e par ces wishbookers",
"step_usertype_space_title": "Choisissez votre espace",
"step_usertype_space_description": "Chacun des espaces vous donne accès à certaines configurations de coffre-fort",
"classic_step_usertype_title": "Classic",
"classic_step_usertype_description": "Le coffre Classic est destiné aux +18 ans",
"kid_step_usertype_title": "Kids",
"kid_step_usertype_description": "Le coffre Kids est destiné aux 0-18ans",
"onboarding_step_payment_title": "Une souscription simple",
"onboarding_step_payment_description": "Un prix clair pour votre solution Wishbook",
"ob_classic_freemium_q1": "Parmi les propositions suivantes, sélectionnez de quoi est composé votre patrimoine :",
"ob_classic_freemium_q1_description": "Vous pouvez sélectionner plusieurs options",
"ob_classic_freemium_q1_opt_1": "Résidence Principale",
"ob_classic_freemium_q1_opt_2": "Résidence Secondaire / Terrain",
"ob_classic_freemium_q1_opt_3": "Compte Epargne",
"ob_classic_freemium_q1_opt_4": "Assurance Vie",
"ob_classic_freemium_q1_opt_5": "Entreprise(s)",
"ob_classic_freemium_q1_opt_6": "Assurance Décès",
"ob_classic_freemium_q1_opt_7": "Assurance Retraite",
"ob_classic_freemium_q1_opt_8": "Actions Boursières",
"ob_classic_freemium_q1_opt_9": "Cryptomonnaie",
"ob_classic_freemium_q1_opt_10": "Collections & Meubles Anciens",
"ob_classic_freemium_q1_opt_11": "Testament",
"ob_classic_freemium_q1_opt_12": "Véhicules",
"ob_classic_freemium_q1_opt_13": "Oeuvres d'art",
"ob_classic_freemium_q1_opt_14": "Bijoux & Métaux Précieux",
"ob_classic_freemium_q1_opt_15": "Coffre-fort",
"ob_classic_freemium_q2": "A quel montant est estimé votre patrimoine ?",
"ob_classic_freemium_q2_opt_1": "Entre 0€ et 100 000€",
"ob_classic_freemium_q2_opt_2": "Entre 100 000€ et 300 000€",
"ob_classic_freemium_q2_opt_3": "Entre 300 000€ et 500 000€",
"ob_classic_freemium_q2_opt_4": "Entre 500 000€ et 800 000€",
"ob_classic_freemium_q2_opt_5": "Plus de 800 000€",
"ob_classic_freemium_q3": "Avez-vous des enfants ?",
"ob_classic_freemium_q3_opt_1": "Oui",
"ob_classic_freemium_q3_opt_2": "Non",
"ob_classic_freemium_q3_q1": "Si oui, combien ?",
"onboarding_nb_of_children_error": "Vous devez renseigner le nombre d'enfants",
"ob_classic_freemium_q4": "Quelle est votre situation ?",
"ob_classic_freemium_q4_opt_1": "Célibataire",
"ob_classic_freemium_q4_opt_2": "Marié(e)",
"ob_classic_freemium_q4_opt_3": "Divorcé(e)",
"ob_classic_freemium_q4_opt_4": "En concubinage",
"ob_classic_freemium_q4_opt_5": "Pacé(e)",
"ob_classic_freemium_q4_opt_6": "Veuf.ve",
"ob_classic_freemium_q5": "Êtes-vous déjà passé par un Notaire ?",
"ob_classic_freemium_q5_opt_1": "Oui",
"ob_classic_freemium_q5_opt_2": "Non",
"ob_classic_freemium_q6": "Avez-vous déjà rédigé un Testament ?",
"ob_classic_freemium_q6_opt_1": "Oui",
"ob_classic_freemium_q6_opt_2": "Non",
"ob_classic_freemium_q7": "Quel(s) service(s) Wishbook vous intéresse(nt) le plus ?",
"ob_classic_freemium_q7_opt_1": "Mon Testament et Répartition des Biens",
"ob_classic_freemium_q7_opt_2": "Mes Documents Importants",
"ob_classic_freemium_q7_opt_3": "Mes Directives Médicales",
"ob_classic_freemium_q7_opt_4": "Mes Volontés Funéraires",
"ob_classic_freemium_q7_opt_5": "Mes Souvenirs Précieux",
"ob_classic_freemium_q7_opt_6": "Mes Mots de Passe",
"onboarding_step_kyc_title": "Vérification d'identité",
"onboarding_step_kyc_description": "Merci de ne pas interrompre le processus de vérification d’identité. Les captures ne sont ni utilisées ni affichées par Wishbook.\nSélectionnez \"Continuer sur mobile\" lorsque cela vous sera proposé.",
"onboarding_error_kyc_failure": "La vérification de votre identité a échoué. Veuillez contacter notre support client.",
"step_kyc_support_error_format": "Bonjour, j'ai une erreur KYC {0}",
"onboarding_step_userinfo_title": "Informations personnelles",
"onboarding_step_userinfo_description": "Veillez à ce que ces informations soient exactes",
"step_userinfo_field_firstname": "Prénom",
"step_userinfo_field_lastname": "Nom",
"step_userinfo_field_dob": "Date de naissance",
"step_userinfo_field_phone": "Téléphone",
"step_userinfo_newsletter_title": "Newsletter",
"step_userinfo_newsletter_subtitle": "Je souhaite recevoir la newsletter de Wishbook (promis il n'y aura pas de spam)",
"step_userinfo_next_action": "Suivant",
"step_userinfo_done_action": "Terminer",
"kid_user_info": "Informations sur l'enfant",
"kid_gender_title": "Genre",
"kid_gender_male": "Garçon",
"kid_gender_female": "Fille",
"kid_gender_other": "Autre",
"kid_binding_title": "Lien avec l'enfant",
"kid_binding_child": "Mon enfant",
"kid_binding_grandchild": "Mon petit fils/Ma petite fille",
"kid_binding_nephewniece": "Mon neuveu/Ma nièce",
"kid_binding_friend": "L'enfant d'un ami",
"kid_binding_other": "Autre",
"onboarding_step_passphrase_title": "Votre Passphrase",
"onboarding_step_passphrase_description": "Comme tout coffre, celui de Wishbook a une clé, il s'agit de votre passphrase.",
"onboarding_step_passphrase_disclaimer": "Conserver cette passphrase est PRIMORDIAL. Elle est nécessaire pour accéder à votre coffre, de n'importe où et en toute sécurité. Si elle est perdue, vos données ne seront pas récupérables.",
"onboarding_step_passphrase_intro": "Votre Passphrase :",
"onboarding_step_passphrase_regenerate_action": "Générer à nouveau",
"onboarding_step_passphrase_download_action": "Télécharger",
"onboarding_step_passphrase_checkbox": "J'ai téléchargé la clé, je m'engage à la conserver précieusement à vie pour pouvoir accéder à mon Wishbook.",
"onboarding_step_passphrase_create_action": "Ouvrir mon coffre Wishbook",
"kid_step_configuration_title": "Configuration",
"kid_step_configuration_description": "Vous, ainsi que vos proches, pouvez alimenter votre coffre uniquement 4 semaines par an, comme par exemple pour les fêtes ou les anniversaires",
"kid_step_configuration_opening_date_title": "Configurez ici les 4 dates d'ouverture de votre coffre, chaque année il sera alors disponible pendant 7 jours à partir de ces dates :",
"kid_step_configuration_opening_date_first": "Première période d'ouverture",
"kid_step_configuration_opening_date_second": "Seconde période d'ouverture",
"kid_step_configuration_opening_date_third": "Troisième période d'ouverture",
"kid_step_configuration_opening_date_fourth": "Dernière période d'ouverture",
"kid_step_configuration_storage_member_titile": "Configurez ici l'espace de stockage que vous souhaitez réserver pour chacun des membres qui auront accès à votre coffre. Sélectionnez 0 pour leur permettre d'utiliser l'espace de stockage du coffre entier.",
"classic_step_guardian_title": "Designez vos Gardiens",
"kid_step_guardian_title": "Designez vos Proches",
"classic_step_guardian_description": "Après cette étape et afin que votre coffre prenne tout son sens, vous pourrez désigner jusqu'à trois gardiens pour votre Wishbook. Il vous faudra communiquer à chacun une clé de sécurité unique. Ils n'obtiendront l'accès aux dossiers de votre coffre qu'après la présentation d'un acte de décès.",
"kid_step_guardian_description": "Pour que Wishbook prenne tout son sens, vous pouvez désigner autant de proches, amis ou famille, que vous le souhaitez. Il pourront voir tout ce que vous mettez dans votre coffre, et pourront participer en alimentant un Album et un dossier des Documents qui leur sont dédiés.",
"step_guardian_action": "Accéder à mon coffre Wishbook",
"space_select_title": "Sélectionnez votre espace",
"space_select_description": "Vous pouvez voir ici les espaces auxquels vous avez accès",
"space_select_passphrase_description": "Entrez la clé de sécurité qui vous a été remise pour accéder à son espace",
"space_select_title_select": "Cliquez sur le Wishbooker vous ayant désigné",
"space_select_title_create": "Ou alors créer votre espace",
"classic_space_select": "CLASSIC - LE COFFRE DES 18+ ANS",
"kids_space_select": "KIDS - LE COFFRE DES 0-18 ANS",
"space_select_action_create_format": "Créer mon espace {0}",
"space_select_action_go_format": "Accéder à mon espace {0}",
"space_select_go_previous": "Précédent",
"space_select_logout": "Se déconnecter",
"keyring_title": "Sécurité",
"keyring_description": "Vous maîtrisez l'accès à votre coffre",
"keyring_wishbooker_error": "Votre clé n'apparaît pas être celle que vous avez générée lors de l'ouverture de votre coffre.",
"keyring_guardian_error": "Votre clé n'apparaît pas être celle qui vous a été transmise par le Wishbooker.",
"keyring_passphrase_required": "Une clé valide est nécessaire pour ouvrir votre coffre",
"keyring_verify": "Verifier",
"keyring_label": "Clé du coffre Wishbook",
"keyring_lost_label": "Passephrase perdue ?",
"keyring_lost_link": "Contactez-nous",
"keyring_logout": "Se déconnecter",
"sc_used_format": "Espace utilisé :",
"sc_cta": "Ajouter de l'espace",
"passphrase_notification_description": "Votre passphrase est nécessaire afin d'accéder à votre coffre Wishbook. Veuillez la télécharger et la garder précieusement, elle vous sera demandée pour toutes vos connexions",
"home_title": "Wishbook | Bienvenue",
"home_header_hello": "Bonjour,",
"home_header_welcome_format": "Bienvenue {0},",
"home_header_welcome_guardian_format": "Bienvenue dans le coffre de {0},",
"home_header_news": "Que souhaitez-vous ajouter à votre succession aujourd'hui ?",
"home_header_my_video": "Ma vidéo",
"home_header_my_complete": "Complete",
"home_safe_closed": "Vos dossiers sont désormais verrouillés. Cliquez dessus pour les déverouiller.",
"home_counter_title": "Fermeture de votre Wishbook dans",
"home_counter_days": "JOURS",
"home_counter_hours": "HEURES",
"home_counter_minutes": "MINUTES",
"home_safe_card_list_title": "Mon Wishbook",
"home_safe_card_guardian_list_title": "Son Wishbook",
"home_safe_card_title1": "Souvenirs d’une vie",
"home_safe_card_desc1": "Racontez les souvenirs les plus forts de votre existence.",
"home_safe_card_guardian_desc1": "Votre proche a raconté son histoire de vie",
"home_safe_card_title2": "Volontés Funéraires",
"home_safe_card_desc2": "Que souhaitez-vous pour ce dernier événement de votre vie ?",
"home_safe_card_guardian_desc2": "Ses envies pour le dernier événement de sa vie.",
"home_safe_card_title3": "Directives Anticipées",
"home_safe_card_desc3": "En cas d’incapacité d’exprimer votre volonté, vos gardiens sauront",
"home_safe_card_guardian_desc3": "En cas d’incapacité d’exprimer ses volontés, votre proche a laissé des directives",
"home_safe_card_title4": "Testament Olographe",
"home_safe_card_desc4": "Rédigez votre testament à la main en suivant des règles simples ou stipulez l'existance d'un testament",
"home_safe_card_guardian_desc4": "Votre proche y laisse son Testament",
"home_safe_card_title5": "Documents Officiels",
"home_safe_card_desc5": "Transmettez les documents légaux et importants qui attestent de votre Patrimoine.",
"home_safe_card_guardian_desc5": "Pour vous transmettre les documents légaux et importants de son Patrimoine.",
"home_safe_card_title6": "Patrimoine Numérique",
"home_safe_card_desc6": "Répertoriez vos accès pour faciliter les démarches de vos Gardiens",
"home_safe_card_guardian_desc6": "Tous les accès pour vous faciliter les démarches",
"home_start_guide_title": "Je démarre mon wishbook",
"home_start_guide_subtitle1": "Ici, vous remplissez la base de votre Wishbook.",
"home_start_guide_subtitle2": "Ce sera le point de départ de la vidéo de votre vie.",
"home_start_guide_more_info": "En savoir plus",
"home_start_guide_card_1": "1. Mes parents",
"home_start_guide_coming_soon": "Votre guide Wishbook arrive bientôt...",

// // (KIDS)
"kid_safe_albums_title": "Ses souvenirs",
"kid_safe_albums_desc": "Réunissez les souvenirs les plus forts depuis sa naissance",
"kid_safe_albums_member_desc": "",
"kid_safe_documents_title": "L'essentiel de ses documents",
"kid_safe_documents_desc": "Répertoriez ses documents les plus importants",
"kid_safe_documents_member_desc": "",
"kid_safe_health_title": "Son carnet de santé",
"kid_safe_health_desc": "Réunissez les éléments médicaux qu’il conservera toute sa vie",
"kid_safe_health_member_desc": "",
"kid_safe_pot_title": "Une cagnotte éphémère",
"kid_safe_cagnotte_desc": "Optez pour des cadeaux communs lors des anniversaires",
"kid_safe_cagnotte_member_desc": "",
"kid_safe_savings_title": "Son livret d'épargne",
"kid_safe_savings_desc": "Constituez une épargne qui lui servira à sa majorité",
"kid_safe_savings_member_desc": "",
"kid_safe_timelapse_title": "Une vidéo de 0 à 18 ans",
"kid_safe_timelapse_desc": "Créez une vidéo accélérée de sa naissance à sa majorité",
"kid_safe_timelapse_member_desc": "",
"guardian_passphrase_title": "Passphrase",
"guardian_passphrase_subtitle": "Attention ! Vous avez la charge de transmettre cette passphrase à votre Gardien.",
"guardian_added_toast": "Nouveau Gardien créé !",
"guardian_cannot_be_added": "Vous ne pouvez plus ajouter de nouveaux Gardiens",
"kid_creation_title": "Ajouter un membre",
"guardian_creation_title": "Ajouter un gardien",
"kid_creation_subtitle": "Vous pouvez ici désigner un nouveau membre",
"guardian_creation_subtitle": "Vous pouvez ici désigner un nouveau gardien",
"guardian_creation_field_email": "Email",
"guardian_creation_field_firstname": "Prénom",
"guardian_creation_field_lastname": "Nom",
"guardian_creation_field_dob": "Date de naissance",
"guardian_creation_field_phone": "Téléphone",
"guardian_creation_close": "Fermer",
"guardian_creation_next": "Suivant",
"guardian_creation_validate": "Ajouter",
"guardian_update_passphrase_title": "Attention ! Vous avez la charge de transmettre cette passphrase à votre Gardien. Son ancienne passphrase ne sera plus utilisable.",
"guardian_update_phassphrase_subtitle_format": "Mise à la jour de la passphrase de",
"guardian_email_title": "Passphrase à jour !",
"guardian_email_subtitle": "Pour plus de facilité, envoyez un email à votre Gardien avec sa Passphrase",
"guardian_updated_toast": "Gardien mis à jour !",
"guardien_required_action_title": "Une action de votre part est requise",
"guardien_required_action_description": "Dans le but de rendre votre espace encore plus sécurisé, nous mettons à jour le protocole de sécurité qui concerne vos Gardiens. Pour cela, vous devez mettre à jour la Passphrase de chacun de vos Gardiens.",
"guardien_required_action": "Mettre à jour",
"guardian_passphrase_disclaimer": "Vous devez télécharger la Passphrase en pdf et cocher la case présente en bas de cet écran afin de poursuivre.",
"guardian_passphrase_download_action": "Télécharger",
"guardian_passphrase_intro": "Passphrase de votre Gardien :",
"guardian_passphrase_instruction": "Téléchargez le document puis envoyez-le à votre Gardien.",
"guardian_passphrase_checkbox": "Je télécharge et j’envoie la Passphrase à mon Gardien.",
"guardian_passphrase_action_close": "Annuler",
"guardian_passphrase_action_save": "Enregistrer",
"guardian_send_action": "Envoyer par email",
"guardian_action_done": "Terminer",
"guardian_deletetion_confirmation_title": "Êtes-vous sûr de vouloir supprimer ce gardien ?",
"guardian_deletion_toast": "Gardien supprimé !",
"guardian_list_title": "Gardiens",
"guardian_add_action": "Ajouter un Gardien",
"kid_member_card_title": "Vos proches",
"kid_member_add_action": "Ajouter un proche",
"classic_members_title": "Gardiens",
"classic_members_description": "Vos Gardiens seront responsables de l'ouverture de votre coffre Wishbook.",
"kids_members_title": "Amis & famille",
"kids_members_description": "Vos amis et votre famille participent à la construction du coffre de votre enfant. Ils ont accès à l'ensemble des sections et peuvent aussi télécharger leur contenu dans les albums et les documents.",
"member_lock_title": "Accès restreint",
"classic_member_lock_subtitle": "En tant que Gardien, vous n’aurez pas accès à ce coffre du vivant de votre Wishbooker.",
"kids_member_lock_subtitle": "En tant que Proche désigné, vous n'avez pas accès à ce coffre pour le moment.",
"classic_member_lock_action_support": "Je souhaite déclarer la perte de mon proche",
"kids_member_lock_action_support": "Je souhaite avoir accès à cet espace",
"member_lock_or": "ou",
"member_to_wishbooker_action": "Créez vous-même votre coffre-fort en devenant Wishbooker",
"safe_lock_title": "Coffre verrouillé",
"classic_safe_lock_subtitle_cause_1": "Votre coffre a été verrouillé car il a été créé depuis plus d'une année",
"classic_safe_lock_subtitle_cause_2": "Votre coffre est de nouveau verrouillé après une semaine d'ouverture",
"classic_safe_lock_subtitle_cause_3": "Votre coffre a été verrouillé après le mois d'essai de l'offre Freemium",
"kids_safe_lock_subtitle": "Vos coffres ont été verrouillés car ils ont été créés depuis plus d'une année",
"subscription_lock_title": "Accès restreint",
"subscription_lock_subtitle": "Votre niveau de souscription ne vous permet pas d'avoir accès à ce dossier.",
"subscription_lock_action_support_title": "Une question ? Besoin d'aide ?",
"subscription_lock_action_support_subtitle": "Contactez un de nos conseillers immédiatemment sur le tchat ou laissez-nous vous rappeler à l'heure qui vous convient.",
"subscription_lock_action_support_tchat_cta": "Démarrer une conversation",
"subscription_lock_action_support_appointment_cta": "Rendez-vous téléphonique",
"subscription_lock_support_message": "J'aimerais avoir accès à une section restreinte",
"subscription_title": "Souscriptions",
"subscription_description": "Retrouvez ici l'intégralité de vos souscriptions passées et en cours ainsi que les offres Wishbook disponibles.",
"subscription_member_description": "Retrouvez ici l'intégralité des souscriptions passées et en cours de votre Wishbooker",
"subscription_classic_active_user_title": "Souscriptions actives",
"subscription_classic_active_user_subtitle": "Ces souscriptions sont actuellement en cours d'utilisation :",
"subscription_classic_expired_user_title": "Souscriptions expirées",
"subscription_classic_expired_user_subtitle": "Vous retrouvez ici l'historique des souscriptions expirées",
"subscription_classic_available_title": "Offres Wishbook Disponibles",
"subscription_classic_available_subtitle": "Optez pour une formule ou une simple extension, Wishbook vous accompagne tout au long de votre parcours",
"subscription_payment_title": "Nouvelle souscription",
"subscription_payment_description": "Vous vous apprêtez à souscrire à cette nouvelle offre, vous pouvez utiliser l'un de nos systèmes de paiement dont le paiement en plusieurs fois !",
"subscription_classic_freemium_title": "Wishbook Freemium",
"subscription_classic_freemium_price": "Gratuit",
"subscription_classic_freemium_duration": "Essai 1 mois",
"subscription_classic_security": "Data chiffrée de bout en bout",
"subscription_classic_freemium_desc2": "3 Gardiens inclus",
"subscription_classic_freemium_desc3": "500 Mo de stockage",
"subscription_classic_freemium_folders_title": "2 dossiers inclus :",
"subscription_classic_freemium_folders_1": "Volontés funéraires,",
"subscription_classic_freemium_folders_2": "Documents de patrimoine",
"subscription_classic_premium_title": "Wishbook à vie",
"subscription_classic_premium_price": "199€",
"subscription_classic_freemium_to_premium_price": "179€",
"subscription_classic_premium_duration": "/ paiement unique (pas d’abonnement)",
"subscription_classic_premium_desc2": "3 Gardiens inclus",
"subscription_classic_premium_desc3": "2GO de stockage",
"subscription_classic_premium_folders_title": "6 dossiers inclus :",
"subscription_classic_premium_folders_1": "Documents de patrimoine,",
"subscription_classic_premium_folders_2": "Volontés funéraires,",
"subscription_classic_premium_folders_3": "Héritage numérique,",
"subscription_classic_premium_folders_4": "Testament,",
"subscription_classic_premium_folders_5": "L’essentiel des souvenirs Directives médicales",
"subscription_classic_1week_title": "Réouverture de votre coffre",
"subscription_classic_1week_price": "5€",
"subscription_classic_1week_duration": "/une semaine",
"subscription_classic_1week_desc1": "Accès complet à votre coffre pendant 1 semaine si vous souhaitez le mettre à jour",
"subscription_classic_3members_title": "Gardien Supplémentaire",
"subscription_classic_3members_price": "10€",
"subscription_classic_3members_duration": "/à vie",
"subscription_classic_3members_desc1": "Ajouter un Gardien en plus",
"subscription_classic_2go_title": "Stockage Supplémentaire",
"subscription_classic_2go_price": "39€",
"subscription_classic_2go_duration": "/à vie",
"subscription_classic_2go_desc1": "2GO à utiliser comme bon vous semble dans votre Espace Classique",
"subscription_kids_premium_title": "Kids Premium",
"subscription_kids_premium_price": "180€",
"subscription_kids_premium_duration": "/jusqu'à ses 18 ans",
"subscription_kids_premium_desc1": "1 accès à mon coffre sécurisé",
"subscription_kids_premium_desc2": "6GO de stockage",
"subscription_kids_premium_desc3": "Proches désignés illimités",
"subscription_kids_premium_desc4": "6 dossiers dans votre coffre",
"subscription_kids_basic_title": "Kids Basique",
"subscription_kids_basic_price": "100€",
"subscription_kids_basic_duration": "/jusqu'à ses 18 ans",
"subscription_kids_basic_desc1": "1 accès à mon coffre sécurisé",
"subscription_kids_basic_desc2": "3GO de stockage",
"subscription_kids_basic_desc3": "Proches désignés illimités",
"subscription_kids_basic_desc4": "2 dossiers dans votre coffre - Albums & Documents",
"subscription_kids_2go_title": "Stockage supplémentaire",
"subscription_kids_2go_price": "10€",
"subscription_kids_2go_duration": "/à vie",
"subscription_kids_2go_desc1": "2GO à utiliser comme bon vous semble dans votre espace Kids",
"subscription_select_stripe_payment": "Payer en une fois",
"subscription_select_alma_payment": "Payer en 3 fois sans frais",
"subscription_select_coupon": "Payer avec un coupon",
"subscription_select_start": "Découvrir",
"subscription_select_finish": "J'ai compris",
"subscription_select_success_description": "Wishbook vous remercie de votre confiance. Vous avez dès à présent accès à cette section.",
"passphrase_title": "Sauvegarde de votre clé",
"passphrase_wishbook_baseline": "Votre succession entre de bonnes mains, les vôtres.",
"passphrase_intro": "Ce document est adressé uniquement à la personne dont le nom figure ci-dessous.",
"passphrase_data_fullname": "Nom et Prénom",
"passphrase_data_dob": "Date de naissance",
"passphrase_data_notes": "Notes",
"passphrase_data_passphrase": "Clé",
"passphrase_info_1": "Ceci est votre clé d'accès à votre coffre Wishbook.",
"passphrase_info_2": "Il est nécessaire que vous la conserviez dans un endroit hautement sécurisé, à l'abri de tout regard.",
"passphrase_info_3": "Associée à votre mot de passe utilisateur, cette clé vous permet d'accéder en toute sécurité à vos données depuis n'importe quel support et endroit.",
"passphrase_wishbook_thanks": "Wishbook vous remercie de votre confiance",

// // (Checkout form)
"payment_error_format": "Le paiement a échoué :",
"payment_customer_form_title": "Informations de contact pour le paiement ",
"payment_form_email": "Email",
"payment_form_fullname": "Nom et Prénom",
"payment_form_phone": "Téléphone",
"payment_form_address": "Adresse",
"payment_form_zipcode": "Code postal",
"payment_form_city": "Ville",
"payment_payment_form_title": "Carte",
"payment_pay_action_format": "Payer",

// // (Coupon)
"apply_coupon_action": "Appliquer un coupon de réduction",
"applied_coupon_success": "Le coupon a été appliqué avec succès",
"payment_coupon_add_action": "Ajouter un COUPON",
"payment_coupon_reduction_add_action": "Ajouter un COUPON de réduction",
"payment_coupon_title": "Payer avec un COUPON",
"payment_coupon_description": "Vous pouvez payer avec un coupon qui vous a été remis",
"payment_coupon_form_coupon": "COUPON",
"payment_coupon_error": "Votre code est invalide.",
"payment_coupon_use_action": "Utiliser le COUPON",};
