import _ from "lodash";
import { SimpleMeta, Meta } from "src/api/wishbook/meta/model";

export enum MetaType {
  MetaTypeClassicAlbums = "classic-albums",
  MetaTypeKidAlbums = "kid-albums",

  MetaTypeClassicDocuments = "classic-documents",
  MetaTypeKidDocuments = "kid-documents",

  MetaTypeClassicDirectives = "classic-directives",
  MetaTypeClassicCredentials = "classic-credentials",
  MetaTypeClassicTestament = "classic-testament",
  MetaTypeClassicFunerals = "classic-funerals",

  MetaTypeKidTimelapse = "kid-timelapse",
  MetaTypeKidSavings = "kid-savings",
}

export class MetaConfiguration {
  metaList: SimpleMeta[];

  constructor(metaList: SimpleMeta[]) {
    this.metaList = metaList;
  }

  getMetaForType(type: MetaType): SimpleMeta | undefined {
    console.log(`MetaConfiguration getMetaForType => ${type}`);
    return _.find(this.metaList, (meta) => meta.type === type);
  }

  addMeta(meta: Meta|SimpleMeta) {
    console.log(`New meta added t list`);
    console.log(this.metaList);

    if (this.metaList) {
      this.metaList.push(meta);
    } else {
      this.metaList = [meta];
    }
  }

  toJson() {
    return {
      metaList: this.metaList
    }
  }

  static fromJson(payload) {
    if (payload == null) { return new MetaConfiguration(null); }
    return new MetaConfiguration(
      payload.metaList
    );
  }
}