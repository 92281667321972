import { WritableStream } from 'web-streams-polyfill/ponyfill';
// import streamSaver from 'streamsaver';

import { WFile } from "src/api/wishbook/safes/common/WFile";
import { decryptFile, decryptImage } from "src/utils/crypto";
import { readableStreamToBuffer } from "src/utils/streams";

import { TUSD_BASEURL } from "src/constants";
import { PersistentContext } from 'src/contexts/AuthContext/PersistentContext';

let defaultAuthorization = null;

export const assignDefaultAuthorizationBearerToken = (accessToken: string) => {
  if (accessToken) {
    defaultAuthorization = `Bearer ${accessToken}`;
  } else {
    defaultAuthorization = null;
  }
};

export const download = async (file: WFile, context: PersistentContext): Promise<any> => {
  const url = `${TUSD_BASEURL}/files/${file.raw_bucket.tus_id}`;
  console.log(`About to downalod: ${url}`);

  try {
    const response = await fetch(url, { headers: { Authorization: defaultAuthorization } });
    console.log("Download Response >>");
    console.log(response);
    console.log(response.body);

    let readableStream = context.isUsingEncryption() ? decryptFile(response.body, context.getCurrentSpace().keyring) : response.body;

    const newResponse = new Response(readableStream);
    const urlForBlob = URL.createObjectURL(await newResponse.blob())

    const link = window.document.createElement('a');
    link.href = urlForBlob;
    link.setAttribute('download', file.filename);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);

    // clean up Url
    window.URL.revokeObjectURL(urlForBlob);
  }
  catch (error) {
    console.log(error);
  }
}

export enum ImageType {
  Thumbnail = 0,
  Origin = 1,
}

export const downloadImage = async (file: WFile, context: PersistentContext, type: ImageType = ImageType.Thumbnail): Promise<Buffer> => {
  if (type == ImageType.Origin && (file.raw_bucket == null || file.raw_bucket.tus_id == null)) {
    console.log("Bad bucket");
    console.log(file);
    return null;
  }

  if (type == ImageType.Thumbnail && (file.thumbnail_bucket == null || file.thumbnail_bucket.tus_id == null)) {
    console.log("Bad thumbnail_bucket");
    console.log(file);
    return null;
  }

  const url = `${TUSD_BASEURL}/files/${type == ImageType.Origin ? file.raw_bucket.tus_id : file.thumbnail_bucket.tus_id}`;
  try {
    const response = await fetch(url, { headers: { Authorization: defaultAuthorization } });
    console.log(response);
    return context.isUsingEncryption() ? await decryptImage(response.body, context.getCurrentSpace().keyring) : await readableStreamToBuffer(response.body);
  }
  catch (error) {
    console.log(error);
  }
}


