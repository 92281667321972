import { v4 as uuidv4 } from 'uuid';

import { Meta } from 'src/api/wishbook/meta/model';
import { MetaType } from 'src/api/wishbook/meta/MetaConfiguration';
import { WFolder } from "src/api/wishbook/safes/common/WFolder"

export interface MetaDocuments extends Meta {
  content: {
    folders: {
      [prop: string]: WFolder;
    }
  }
}

export const defaultDocuments = (metaType: MetaType): MetaDocuments => {
  return {
    content: {
      folders: { }
    },
    type: metaType,
  };
}

export const addFolder = (documents: MetaDocuments, folder: WFolder) => {
  const key = uuidv4();
  documents.content.folders[key] = folder;
};

export const deleteFolder = (documents: MetaDocuments, folderKey: string) => {
  delete documents.content.folders[folderKey];
};
