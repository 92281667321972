import React, { useEffect, useState } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles,
  SvgIcon, Typography, Grid
} from '@material-ui/core';
import { Menu as MenuIcon } from 'react-feather';
import LogoBlack from 'src/components/LogoBlack';
import type { Theme } from 'src/theme';
import Account from './Account';
import useAuth from "../../../hooks/useAuth";
import { getClassicClosingMoment, SpaceStatus } from '../../../api/wishbook/users/model';
import moment from 'moment/moment';
import { useTranslation } from 'react-i18next';
import Timer from '../../../components/Timer';

interface TopBarProps {
  className?: string;
  onMobileNavOpen?: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    backgroundColor: theme.palette.background.default
  },
  toolbar: {
    minHeight: 64,
  },
toolbarGuardian: {
    minHeight: 64
  },
  logo: {
    height: "38px",
  },
  guardianTypo: {
      color: theme.palette.guardian.main,
  }
}));

const TopBar: FC<TopBarProps> = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles();
  const { context } = useAuth();

  return (
    <AppBar
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Toolbar className={context.isMe() ? classes.toolbar : classes.toolbarGuardian}>
        <Hidden lgUp>
          <IconButton
            color="inherit"
            onClick={onMobileNavOpen}
          >
            <SvgIcon fontSize="small">
              <MenuIcon />
            </SvgIcon>
          </IconButton>
        </Hidden>
        <Hidden>
          <LogoBlack className={classes.logo}/>
        </Hidden>
        <Box
          ml={2}
          flexGrow={1}
        />
        {
          context.isMe() ? (
              <Timer/>
            ) : (
              <Grid container>
                  <Typography variant="h6" color="textPrimary" className={classes.guardianTypo} >
                    Espace gardien
                  </Typography>
                </Grid>
              )
        }
        {/* <Search />
        <Contacts /> */}
        {/* <Hidden mdUp mdDown>
          <Notifications />
          <Settings />
        </Hidden> */}
        <Box ml={2}>
          <Account />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

TopBar.defaultProps = {
  onMobileNavOpen: () => {}
};

export default TopBar;
