import { makeRequest } from 'src/api/wishbook/common/client';

import * as encryptor from "src/utils/crypto/index";
import { PersistentContext } from "src/contexts/AuthContext/PersistentContext";
import { MetaType } from "./MetaConfiguration";
import { SimpleMeta, Meta, } from './model';

export const getMetaList = async (userId: string): Promise<SimpleMeta[]> => {
  const response = await makeRequest('GET', `/api/v1/users/${userId}/meta`);
  return response.data.metas;
};

export async function upsert<T extends Meta>(context: PersistentContext, meta: T): Promise<T> {
  const finalMeta: Meta = {
    id: meta.id,
    type: meta.type,
    content: context.isUsingEncryption() ? encryptor.encryptStructuredObject(meta.content, context.getCurrentSpace().keyring) : meta.content,
  }

  if (finalMeta.id) {
    const response = await makeRequest('PUT', `/api/v1/users/${context.getTargetUserId()}/meta/${finalMeta.id}`, finalMeta);
    return {
      id: response.data.id,
      type: response.data.type,
      content: context.isUsingEncryption() ? encryptor.decryptStructuredObject(response.data.content, context.getCurrentSpace().keyring) : response.data.content,
    } as T;
  }
  const response = await makeRequest('POST', `/api/v1/users/${context.getTargetUserId()}/meta`, finalMeta);
  context.getCurrentSpace().mc.addMeta(response.data);
  return {
    id: response.data.id,
    type: response.data.type,
    content: context.isUsingEncryption() ? encryptor.decryptStructuredObject(response.data.content, context.getCurrentSpace().keyring) : response.data.content,
  } as T;
}

export async function get<T extends Meta>(context: PersistentContext, metaId: string): Promise<T> {
  const response = await makeRequest('GET', `/api/v1/users/${context.getTargetUserId()}/meta/${metaId}`);
  const meta = response.data;
  return {
    id: meta.id,
    type: meta.type,
    content: context.isUsingEncryption() ? encryptor.decryptStructuredObject(meta.content, context.getCurrentSpace().keyring) : meta.content,
  } as T;
}

export async function getByType<T extends Meta>(context: PersistentContext, type: MetaType): Promise<T> {
  const meta: SimpleMeta|undefined = context.getCurrentSpace().mc.getMetaForType(type); 
  if (meta == null) throw new Error(`No meta available for type ${type}`);
  else return get<T>(context, meta.id);
}
